<template>
  <banner text="Schüler-Portal" icon="graduation cap"/>

  <sui-segment v-if="schoolInfo">
    <h3>
      Schüler-Portal
      <br>
      {{ schoolInfo?.address1 }}
    </h3>

    <p>
      {{ schoolInfo?.address2 }}
      <br>
      {{ schoolInfo?.type }}
    </p>

    <sui-list v-if="schoolInfo">
      <sui-list-item>
        <sui-list-icon name="marker"/>
        <sui-list-content>
          {{ schoolInfo.street }}, {{ schoolInfo.zip }} {{ schoolInfo.city }}
        </sui-list-content>
      </sui-list-item>

      <sui-list-item v-if="schoolInfo.phone">
        <sui-list-icon name="phone"/>
        <sui-list-content>
          {{ schoolInfo.phone }}
        </sui-list-content>
      </sui-list-item>

      <sui-list-item v-if="schoolInfo.fax">
        <sui-list-icon name="fax"/>
        <sui-list-content>
          {{ schoolInfo.fax }}
        </sui-list-content>
      </sui-list-item>

      <sui-list-item v-if="schoolInfo.email">
        <sui-list-icon name="mail"/>
        <sui-list-content>
          <a :href="'mailto:' + schoolInfo.email">{{ schoolInfo.email }}</a>
        </sui-list-content>
      </sui-list-item>

      <sui-list-item v-if="schoolInfo.website">
        <sui-list-icon name="globe"/>
        <sui-list-content>
          <a :href="schoolInfo.website">{{ schoolInfo.website }}</a>
        </sui-list-content>
      </sui-list-item>
    </sui-list>

    <sui-segment class="secondary inverted" v-if="schoolInfo.sp_support_mail">
      Hilfe-Email bei Fragen zur Anmeldung <br>
      <b>{{ schoolInfo.sp_support_mail }}</b>
    </sui-segment>
  </sui-segment>

  <changelog v-if="auth"/>

</template>

<script>
import Changelog from "../components/Changelog.vue";
import Banner from "../components/Banner";
import store from "../store";

export default {
  components: { Changelog, Banner },
  name: "About",
  computed: {
    auth() {
      return store.getters['auth/check'];
    },
    schoolInfo() {
      return store.state.auth.schoolInfo;
    }
  },
}
</script>